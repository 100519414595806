import React, {Component} from 'react'

class Footer extends Component {
    render() {
        return(
            <div style={{marginTop:"2%"}}>
                <p>To add texts please email daniel@quisest.net</p>
                <p>For questions please email questions@quisest.net</p>
                <p>Created by Daniel Longo 2020</p>
            </div>
        )
    }
}

export default Footer
